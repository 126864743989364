require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.map");

require("core-js/modules/es.array.slice");

require("core-js/modules/es.function.name");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.replace");

require("core-js/modules/es.string.split");

require("core-js/modules/web.dom-collections.iterator");

(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "vant/es/dialog/style", "vant/es/dialog", "vant/es/toast/style", "vant/es/toast", "vant/es/action-sheet/style", "vant/es/action-sheet", "vant/es/field/style", "vant/es/field", "vant/es/popup/style", "vant/es/popup", "vant/es/steps/style", "vant/es/steps", "vant/es/step/style", "vant/es/step", "@/utils/time"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("vant/es/dialog/style"), require("vant/es/dialog"), require("vant/es/toast/style"), require("vant/es/toast"), require("vant/es/action-sheet/style"), require("vant/es/action-sheet"), require("vant/es/field/style"), require("vant/es/field"), require("vant/es/popup/style"), require("vant/es/popup"), require("vant/es/steps/style"), require("vant/es/steps"), require("vant/es/step/style"), require("vant/es/step"), require("@/utils/time"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.style, global.dialog, global.style, global.toast, global.style, global.actionSheet, global.style, global.field, global.style, global.popup, global.style, global.steps, global.style, global.step, global.time);
    global.determineRes = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _style, _dialog, _style2, _toast, _style3, _actionSheet, _style4, _field, _style5, _popup, _style6, _steps, _style7, _step, _time) {
  "use strict";

  var _interopRequireDefault = require("/root/deploy/iot-product-tool/node_modules/@babel/runtime/helpers/interopRequireDefault");

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _dialog = _interopRequireDefault(_dialog);
  _toast = _interopRequireDefault(_toast);
  _actionSheet = _interopRequireDefault(_actionSheet);
  _field = _interopRequireDefault(_field);
  _popup = _interopRequireDefault(_popup);
  _steps = _interopRequireDefault(_steps);
  _step = _interopRequireDefault(_step);
  var _default = {
    name: "DetermineRes",
    components: {
      Step: _step.default,
      Steps: _steps.default,
      Popup: _popup.default,
      Field: _field.default,
      ActionSheet: _actionSheet.default
    },
    data: function data() {
      var ossHost = this.$config.base.ossHost;
      return {
        ossHost: ossHost,
        tagId: 0,
        probeInfo: {},
        tagList: [],
        editTagId: '',
        editTagName: '',
        editResponsibleParty: '',
        isShowModal: false,
        areaList: [],
        showArea: false,
        isShowTagAction: false,
        isShowResponsibleAction: false,
        editResponsiblePartyText: '',
        actiobArr: [{
          name: '英沃',
          id: 4
        }, {
          name: '览宋',
          id: 5
        }]
      };
    },
    created: function created() {
      var vm = this;
      var USER_NAME = vm.$config.keys.USER_NAME;
      var name = vm.$localStorage.getItem(USER_NAME);
      var uuid = vm.$localStorage.getItem('uuid') || vm.$route.query.uuid;
      var fType = vm.$strTool.filterFactoryType(uuid);
      vm.name = name;

      if (fType != 'lansong') {
        vm.$router.replace({
          path: "404"
        });
        return;
      }

      vm.loadTag();
    },
    methods: {
      loadProbeInfo: function loadProbeInfo() {
        var vm = this;
        vm.$http({
          type: "get",
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "careInfo/").concat(vm.$route.query.pId)
        }).then(function (res) {
          if (!res.data) {
            (0, _toast.default)("网络错误");
            return;
          }

          if (res.data) {
            res.data.logs.map(function (log) {
              log.dayText = (0, _time.getYMD)(log.createAt).split('  ')[0].slice(5, 10);
              log.hourText = (0, _time.getYMD)(log.createAt).split('  ')[1].slice(0, 5);
              log.time = (0, _time.getYMD)(log.createAt);
            });
            vm.tagList.map(function (tag) {
              tag.id == res.data.preTagId && (res.data.tagName = tag.tagName);
            });
            vm.editTagId = res.data.preTagId;
            vm.editTagName = res.data.tagName;
            vm.editResponsibleParty = res.data.preResponsibleParty;
            vm.editResponsiblePartyText = res.data.preResponsibleParty == 5 ? '览宋' : '英沃';
          }

          vm.probeInfo = res.data;
        });
      },
      onCancel: function onCancel() {// this.editTagId = ''
        // this.editTagName = ''
        // this.editResponsibleParty = ''
        // this.editResponsiblePartyText = ''
      },
      onSelectTag: function onSelectTag(item) {
        this.editTagId = item.id;
        this.editTagName = item.name;
      },
      onSelectResponsible: function onSelectResponsible(item) {
        this.editResponsibleParty = item.id;
        this.editResponsiblePartyText = item.name;
      },
      loadTag: function loadTag() {
        var vm = this;
        var params = {
          type: 6
        };
        vm.$http({
          type: "get",
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "returnFac/tags"),
          params: params
        }).then(function (res) {
          res.data && res.data.map(function (tag) {
            tag.name = tag.tagName;
          });
          vm.tagList = res.data;
          vm.loadProbeInfo();
        });
      },
      onsure: function onsure() {
        var vm = this;

        _dialog.default.confirm({
          title: "是否确认维持原判",
          message: "\u786E\u8BA4\u540E\uFF0C\u8BE5\u8BA2\u5355\u6700\u7EC8\u8D23\u4EFB\u65B9\u5C06\u5224\u5B9A\u4E3A\uFF1A<span class=\"color-span\">".concat(vm.probeInfo.preResponsibleParty == 5 ? '览宋' : '英沃', "</span>"),
          confirmButtonColor: "#32BE32"
        }).then(function () {
          vm.tt = _toast.default.loading('加载中');
          vm.$http({
            type: "POST",
            url: "".concat(vm.$config.base.PROBE_BASE_URL, "careInfo/").concat(vm.probeInfo.id, "/confirm"),
            data: {
              operator: vm.name,
              password: vm.$config.base.password,
              status: 1
            }
          }).then(function (res) {
            vm.tt.clear();

            if (res.code != 1) {
              _toast.default.fail(res.message || '验收失败');

              return;
            }

            _toast.default.success({
              message: '验收成功'
            });

            vm.$router.back();
          }).catch(function (err) {
            console.log(err);
          });
        }).catch(function () {// on cancel
        });
      },
      onchangeStatus: function onchangeStatus() {
        var vm = this;
        var err = '';
        !vm.editTagId && (err = '请选择故障原因');
        !vm.editResponsibleParty && (err = '请选择责任方');

        if (err) {
          _toast.default.fail(err);

          return;
        }

        vm.tt = _toast.default.loading('加载中');
        vm.$http({
          type: "POST",
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "careInfo/").concat(vm.probeInfo.id, "/confirm"),
          data: {
            operator: vm.name,
            password: vm.$config.base.password,
            status: 2,
            tagId: vm.editTagId,
            responsibleParty: vm.editResponsibleParty
          }
        }).then(function (res) {
          vm.tt.clear();

          if (res.code != 1) {
            _toast.default.fail(res.message || '验收失败');

            return;
          }

          _toast.default.success({
            message: '验收成功'
          });

          vm.$router.back();
        }).catch(function (err) {
          console.log(err);
        });
      }
    }
  };
  _exports.default = _default;
});